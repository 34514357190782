import {DateTime} from "luxon";
import {msToTime} from "../../helpers/helpers";

class PlayerInfo {
    constructor(player) {
        this._player = player;

        this.manageVideoProgress()
        this.setupListeners();
    }

    async manageVideoProgress() {
        console.log('(re)starting video progress count...')

        let pgp = await this.getProgramGuidePlus()

        let now = DateTime.fromFormat(pgp.time, "yyyy'-'LL'-'dd'T'TT'Z'");
        let today = now.startOf('day').toFormat("kkkk'-'MM'-'dd")
        let tomorrow = now.startOf('day').plus({days: 1}).toFormat("kkkk'-'MM'-'dd")
        let liveIndex = pgp['program_guides'][today].findIndex(item => item['is_live']);

        let live_start = DateTime.fromFormat(pgp['program_guides'][today][liveIndex].startTime_dt, "yyyy'-'LL'-'dd'T'TT'Z'");
        let live_end = pgp['program_guides'][today][liveIndex + 1] ?
            DateTime.fromFormat(pgp['program_guides'][today][liveIndex + 1].startTime_dt, "yyyy'-'LL'-'dd'T'TT'Z'") :
            DateTime.fromFormat(pgp['program_guides'][tomorrow][0].startTime_dt, "yyyy'-'LL'-'dd'T'TT'Z'");

        now = now.valueOf()
        live_start = live_start.valueOf();
        live_end = live_end.valueOf();

        now = now - live_start;
        live_end = live_end - live_start;

        $('.live-info h4').html('<i class="fas fa-tv"></i> ' + pgp['program_guides'][today][liveIndex].title)

        if($('.live-info').hasClass('hidden')){
            let item = pgp['program_guides'][today][liveIndex];
            item['isLivePlaying'] = true;
            await this._player.manageView(item);
        }

        let videoProgressInterval = setInterval(() => {
            let percentage = now / live_end * 100;
            let duration = live_end;
            let remaining = live_end - now;
            $('.status-line .line').css('width', percentage + '%')
            $('.live-time').html(msToTime(duration - remaining) + ' / ' + msToTime(duration))
            if (percentage >= 100) {
                this.manageVideoProgress()
                clearInterval(videoProgressInterval)
            } else {
                now = now + 1000;
            }
        }, 1000);
    }

    setupListeners() {
        $('.more-info').click(() => {
            $('.active-extended-info-modal').addClass('toggled');
            $('.m3-hero').addClass('down');
        })

        $('.active-extended-info-modal').click(() => {
            $('.active-extended-info-modal').removeClass('toggled');
            $('.m3-hero').removeClass('down');
        })

        $('.active-extended-info-modal .ps__rail-x, .active-extended-info-modal .ps__rail-y').click(e => {
            e.stopPropagation();
        })

        $('.live-info').click(() => {
            this._player.updateUrl();
            this._player.start();
        })

        $(".player-icons-container a").click((e) => {
            e.preventDefault();
            $('html,body').animate({
                    scrollTop: $("#apps_container").offset().top
                },
                'slow');
        });
    }

    async getProgramGuidePlus() {
        return JSON.parse(await $.ajax({
            method: 'GET',
            url: '/api/m3/v3/program_guide?days=2',
        }))
    }
}

export default PlayerInfo