import Model from './ReactionsModel';
import View from './ReactionsView';

class Controller {
  constructor(model, view) {
    this._model = model;
    this._view = view;

    this._view.on('stateUpdated', prevState => {
      this._view.render(prevState)
    })

    this._view.on("getReactions", id => {
      this._model.getReactions(id);
    })

    this._view.on("reaction", reaction => {
      this._model.saveReaction(reaction);
    })

    this._model.on("gotReactions", results => {
      console.log(results);
      this._view.setState(results);
    })

    this._model.on("reacted", results => {
      if (results === true) {
        this._view.update();
      }
    })
  }
}

class Reactions {
  constructor(selector) {
    this._model = new Model();
    this._view = new View(this._model, selector);
    this._controller = new Controller(this._model, this._view);
  }

  update(id) { 
    this._view.update(id);
  }
}

export default Reactions;