import {DateTime} from "luxon";
import Player from "./player/Player";
import PlayerInfo from "./player/PlayerInfo";
import DailyProgram from "./dailyProgram/DailyProgram";
import ProgramGuide from "./programGuide/ProgramGuide";
import Sidebar from "./sidebar/Sidebar";
import Helpdesk from "./helpdesk/Helpdesk";
import Report from "./report/Report";

const Tv = (() => {
    let time = null;

    const player = new Player();
    new PlayerInfo(player);
    const dailyProgram = new DailyProgram(player);
    const programGuide = new ProgramGuide(player);
    const sidebar = new Sidebar();
    new Helpdesk();
    new Report();

    async function setTime() {
        let isTimeSetted;

        await $.ajax({
            method: "GET",
            url: '/api/m3/v3/getTime',
            success: response => {
                response = JSON.parse(response);
                let serverTime = DateTime.fromFormat(response.time, "yyyy'-'LL'-'dd'T'TT'Z'");

                if (!time || !time.hasSame(serverTime, 'day') || (time.hour < 4 && serverTime.hour >= 4)) {
                    time = serverTime;
                    isTimeSetted = true;
                } else {
                    isTimeSetted = false;
                }
            }
        });
        return isTimeSetted;
    }

    async function updateModules() {
        if (await setTime()) {
            dailyProgram.getDailyProgram()
            programGuide.getProgramGuide()
            sidebar.getProgramGuide()
        }
    }


    return {
        init: () => {
            setTime()
            $(window).focus(() => {
                updateModules();
            });
        }
    }
})()

export default Tv;