import {DateTime} from "luxon";
import PerfectScrollbar from "perfect-scrollbar";

require('perfect-scrollbar/css/perfect-scrollbar.css');

class ProgramGuide {
    constructor(player) {
        this._player = player;
        this.pg_scrollbar = null;

        this.getProgramGuide();

        this.setupListeners();
    }

    renderProgramGuide(program_guides) {
        if (!Object.keys(program_guides).length) {
            $('.program-guide').removeClass('loading');
            return;
        }
        let date = Object.keys(program_guides)[0];

        let rows = program_guides[date].map(item => {
            let display_time = DateTime.fromFormat(item.startTime_dt, "yyyy'-'LL'-'dd'T'TT'Z'").setLocale('hu').toFormat("HH':'mm");

            return `
            <div class="guide-row  " ${item.playable ? `data-id="${item.id}"` : ''} >
                <div class="guide-time">
                    ${display_time ? display_time : ""}
                </div>
                <div class="guide-titles">
                    ${item.playable ? '<i class="fas fa-play-circle play-btn"></i>' : ''}
                    <div class="titles">
                        <h5>${item.title}<h5>
                        <h6>${item.subtitle ? item.subtitle : '<br>'}</h6>
                    </div>
                    <div class="icons">
                        ${!isNaN(item.pg) ? `
                        <div class="guide-pg">
                            <span class="pg pg-${item.pg}">${isNaN(item.pg) ? "" : item.pg}</span>
                        </div>
                        ` : ''}
                        
                        ${item.hasSubtitle ? `
                        <i class="far fa-closed-captioning"></i>
                        ` : ''}
                        
                        ${item.quality && item.quality !== 'SD' ? `
                        <span class="guide-icon show-icon resolution-icon ${item.quality}">${item.quality}</span>
                        ` : ''}
                    </div>
                </div>
            </div>
            `
        });

        $('.program-guide').html(rows.join(''));
        $('.program-guide').removeClass('loading');

        setTimeout(() => {
            this.pg_scrollbar.update();
        }, 100);

        let next = DateTime.fromISO(date).plus({day: 1});
        let prev = DateTime.fromISO(date).minus({day: 1});
        let start = DateTime.now().startOf('day').minus({day: 1});
        let end = DateTime.now().startOf('day').plus({day: 6});

        if (prev < start) {
            $('.guide-arrow.prev').fadeOut();
            $('.program-guide-date .prev [data-date]').fadeOut();
        } else {
            $('.guide-arrow.prev').attr('data-date', prev.toISODate());
            $('.guide-arrow.prev').fadeIn();
            $('.program-guide-date .prev [data-date]').attr('data-date', prev.toISODate());
            $('.program-guide-date .prev [data-date]').fadeIn();
        }

        if (next > end) {
            $('.guide-arrow.next').fadeOut();
            $('.program-guide-date .next [data-date]').fadeOut();
        } else {
            $('.guide-arrow.next').attr('data-date', next.toISODate());
            $('.guide-arrow.next').fadeIn();
            $('.program-guide-date .next [data-date]').attr('data-date', next.toISODate());
            $('.program-guide-date .next [data-date]').fadeIn();
        }

        let title = DateTime.fromISO(date).setLocale('hu').toFormat("LLLL d'.' EEEE");
        $('.program-guide-date .pg-date').css("text-transform", "capitalize");
        $('.program-guide-date .pg-date').html(title);
    }

    setupListeners() {
        this.pg_scrollbar = new PerfectScrollbar('#program_guide');

        $('#program_guide_container .prev, #program_guide_container .next').click((e) => {
            let date = $(e.target).closest('[data-date]').attr('data-date');
            $('.program-guide').addClass('loading');
            this.getProgramGuide(date);
        });

        $('.program-guide').click((e) => {
            let id = $(e.target).closest('[data-id]').attr('data-id');
            if (id) {
                this._player.updateUrl(id);
                this._player.start(id);
            }
        });
    }

    getProgramGuide(date = '') {
        $.ajax({
            method: "GET",
            url: '/api/m3/v3/program_guide?start=' + date,
            success: response => {
                response = JSON.parse(response);
                if (Object.keys(response.program_guides).length) {
                    this.renderProgramGuide(response.program_guides)
                }
            }
        })
    }
}

export default ProgramGuide