class Helpdesk {
    constructor() {
        this.requestLoading = false;

        this.setupListeners();
    }

    requestSuccess() {
        $('[name="request"]').val('');
        $('.m3-request-form').hide();
        $('.m3-request-success').removeClass('hidden');
        $('.m3-request-success').html('<p class="text-center">Köszönjük a visszajelzést!</p>');
    }

    requestError() {
        window.alert('Valami hiba történt, kérjük próbálja meg újra!');
        $('#send_request').removeClass('disabled')
    }

    setupListeners() {
        $('#send_request').click(() => {
            let message = $('[name="request"]').val();
            if (message.length) {
                $('#send_request').addClass('disabled');
                this.request(message);
            }
        })

        $('[name="request"]').on('input', (e) => {
            let value = $(e.target).val();
            if (value.length > 1000) {
                value = value.substring(0, 1000);
                $(e.target).val(value);
            }
        })
    }

    request(message) {
        if (!this.requestLoading) {
            this.requestLoading = true;
            $.ajax({
                url: '/m3/request',
                method: 'POST',
                data: {
                    message
                },
                success: response => {
                    console.log(response)
                    response = JSON.parse(response);
                    if (response.success) {
                        this.requestSuccess();
                    } else {
                        this.requestError()
                    }
                },
                error: error => {
                    console.log(error);
                    this.requestError()
                },
                complete: () => {
                    this.requestLoading = false;
                }
            })
        } else {
            window.alert('A visszajelzés küldése folyamatban van!');
        }
    }
}

export default Helpdesk