class Report {
    constructor() {
        this.reportLoading = false;

        this.setupListeners();
    }

    reportSuccess(response) {
        if (response.success) {
            $('[name="problem_message"]').val('');
            $('[name="problem_name"]').val('');
            $('[name="problem_email"]').val('');
            $('.m3-problem-form').hide();
            $('.m3-problem-success').removeClass('hidden');
            $('.m3-problem-success').html('<p class="text-center">Köszönjük a visszajelzést! Kollégáink hamarosan felveszik Önnel a kapcsolatot!</p>');
        } else {
            $('#send_problem').removeClass('disabled');
            if (response.error) {
                window.alert(response.error);
            } else {
                window.alert('Valami hiba történt, kérjük próbálja meg újra!');
            }
            grecaptcha.reset();
        }
    }

    setupListeners() {
        $(".m3-problem-form").on('submit', (e) => {
            e.preventDefault();
            let data = {
                name: $('[name=problem_name]').val(),
                email: $('[name=problem_email]').val(),
                message: $('[name=problem_message]').val(),
            }
            if (grecaptcha) {
                data.captcha = grecaptcha.getResponse();
            }
            $('#send_problem').addClass('disabled');
            this.report(data);
        })

        $('[name="problem_message"]').on('input', (e) => {
            let value = $(e.target).val();
            if (value.length > 1000) {
                value = value.substring(0, 1000);
                $(e.target).val(value);
            }
        })

        $('[name="problem_email"], [name="problem_name"]').on('input', (e) => {
            let value = $(e.target).val();
            if (value.length > 200) {
                value = value.substring(0, 200);
                $(e.target).val(value);
            }
        })
    }

    report(data) {
        let captcha;
        if (grecaptcha) {
            captcha = grecaptcha.getResponse().length;
        } else {
            captcha = true;
        }
        if (this.reportLoading) return;
        if (!captcha) {
            window.alert('Hiányzó captcha!');
            return;
        }
        this.reportLoading = true;

        let info = window.navigator;
        let _navigator = {};
        for (let i in info) _navigator[i] = info[i];
        delete _navigator.plugins;
        delete _navigator.mimeTypes;
        data['data_dump'] = JSON.stringify(_navigator);

        $.ajax({
            method: 'POST',
            url: '/m3/report',
            data,
            success: (response) => {
                response = JSON.parse(response)
                this.reportSuccess(response);
            },
            error: (error) => {
                window.alert(error.responseText)
                if (grecaptcha) {
                    grecaptcha.reset();
                }
            },
            complete: () => {
                this.reportLoading = false;
            }
        })
    }
}

export default Report