import PerfectScrollbar from "perfect-scrollbar";

class Sidebar {
    constructor() {
        this.sb_scrollbar = null;

        this.getProgramGuide()

        this.setupListeners();
    }

    renderSidebar(program_guides) {
        let date = Object.keys(program_guides)[0];
        let uniqueGuide = [];
        let showIds = [];
        for (let show of program_guides[date]) {
            if (!showIds.includes(show.id) && show.playable) {
                showIds.push(show.id);
                uniqueGuide.push(show);
            }
        }

        let rows = uniqueGuide.map(item => {
            return `
            <div class="guide-row row" data-id="${item.id}">
                <div class="col-xs-3 col-sm-3 thumbnail-container">
                    <div class="program-guide-thumbnail" style="background-image: url(${'/images/m3/' + item.id})">
                        <i class="fas fa-play play-btn"></i>        
                    </div>
                </div>
                <div class="col-xs-9 col-sm-9 program-guide-body-container">
                    <div class="program-guide-body">
                        <div class="row">
                            <div class="col-xs-10 pg-title-container">
                                <h4>${item.title}</h4>                    
                            </div>
                            <div class="col-xs-2 icon-container">
                                ${item.year ? `<span class="year-label">${item.year}</span>` : ''}
                                ${!isNaN(item.pg) ? `<div class="guide-pg"><span class="pg pg-${item.pg}">${isNaN(item.pg) ? "" : item.pg}</span></div>` : ''}
                                ${item.hasSubtitle ? `<i class="far fa-closed-captioning"></i>` : ''}
                                ${item.quality && item.quality !== 'SD' ? `<span class="guide-icon show-icon resolution-icon ${item.quality}">${item.quality}</span>` : ''}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 show-description-container">
                                <h6 class="show-description">${item.short_description ? item.short_description : item.subtitle ? item.subtitle : ''}</h6>                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            `
        })

        $('.program-guide2').html(rows.join(''))

        $('.program-guide-thumbnail').click((e) => {
            let id = $(e.target).closest('.guide-row').attr("data-id");
            window.open(window.location.origin + '/m3/' + id);
        }).hover((e) => {
            $(e.target).animate({'zoom': 1.4}, 100);
        }, (e) => {
            $(e.target).animate({'zoom': 1}, 100);
        })

        setTimeout(() => {
            this.sb_scrollbar.update();
        }, 100)
    }

    setupListeners() {
        this.sb_scrollbar = new PerfectScrollbar('.program-guide2', {
            suppressScrollX: true
        });
    }

    getProgramGuide(date = '') {
        $.ajax({
            method: "GET",
            url: '/api/m3/v3/program_guide?start=' + date,
            success: response => {
                response = JSON.parse(response);
                this.renderSidebar(response.program_guides)
            }
        })
    }
}

export default Sidebar