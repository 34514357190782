import {setCookie} from "../../helpers/helpers";
import {DateTime} from "luxon";
import PerfectScrollbar from "perfect-scrollbar";
import Reactions from "../../reactions/Reactions";
import '@nava/keyosplayer/keyos-player'

require('perfect-scrollbar/css/perfect-scrollbar.css');
require('@nava/keyosplayer/styles/keyos-default.css');
require('@nava/keyosplayer/styles/default-style.css');
require('../../../css/keyos-custom.css');


class Player {
    constructor() {
        this.initialized_player = false;

        this.setupListeners()

        this.config = {
            pg: {
                "18": "korhatar18_360p.mp4",
                "16": "korhatar16_360p.mp4",
                "12": "korhatar12_360p.mp4",
                "6": "korhatar6_360p.mp4",
                "No PG": "feliratozva_360p.mp4",
                "": "feliratozva_360p.mp4",
            }
        }

        this.volume = 0.5;
        this.player = null;
        this.inPlayer = null;
        let url = window.location.pathname;
        let id = url.substring(url.lastIndexOf('/') + 1) !== 'm3' ? url.substring(url.lastIndexOf('/') + 1) : undefined;
        this.reactions = new Reactions('.reactions')

        this.start(id)
    }

    async start(id) {
        $("html, body").animate({scrollTop: 0}, 600);
        if (this.inPlayer !== id) {
            this.inPlayer = id
            let isLivePlaying = !id;
            id = !id ? (await this.getLive()).id : id;
            let item = await this.getItem(id)
            item['isLivePlaying'] = isLivePlaying;

            this.markSelected(id)

            await this.manageView(item)
            await this.setupPlayer(item);

            this.reactions.update(id);
        }
    }

    updateUrl(id = '') {
        window.history.pushState({urlPath: '/m3/' + (id)}, "", '/m3/' + (id));
        setCookie('MtvaArchivumLastPage', '/m3/' + (id), 1);
    }

    markSelected(id) {
        $(`[data-id]`).removeClass('selected');
        $(`[data-id=${id}]`).addClass('selected');
    }

    async setupPlayer(item) {
        $('#m3_player').removeClass('not-playable');
        $('#m3_player-not-playable').remove();

        if (ALLOWED) {
            if (item.playable) {
                if (this.initialized_player) {
                    this.volume = this.player.volume()
                    // if (CONFIG.env === 'development') $('#m3_player').html('');
                }
                if (item.isLivePlaying) {
                    $('#keyos-player-holder').addClass('live')
                } else {
                    $('#keyos-player-holder').removeClass('live')
                }
                this.initIntro(item);
                this.initPlayer(item);

            } else {
                this.initialized_player = false;

                let dates = [];
                if (item.start_startTime_dts && item.start_playable_dts) {
                    dates = item.start_startTime_dts.concat(item.start_playable_dts)
                } else if (item.start_startTime_dts) {
                    dates = item.start_startTime_dts
                } else if (item.start_playable_dts) {
                    dates = item.start_playable_dts
                }

                dates = dates ? dates.map(
                    dt => DateTime.fromISO(dt).setLocale('hu')) : [];

                dates.sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                });

                let display_dates = [];
                dates.forEach((dt, i) => {
                    if (!display_dates.includes(dt.toFormat("LLLL d'.' EEEE")) &&
                        display_dates.length < 4 &&
                        dt > DateTime.now().setLocale('hu')) {
                        display_dates.push(dt.toFormat("LLLL d'.' EEEE"));
                    }
                })

                $('#m3_player').addClass('not-playable');
                $('#m3_player').parent().append(`
                    <div class="not-playable-box" id="m3_player-not-playable">
                        Ez a műsor jelenleg nem elérhető!<br>
                        ${display_dates.length ? '<br>Legközelebb adásban:<br>' + display_dates.join("<br>") : ""}
                    </div>
                `);
                this.hideIntro()
            }
        }
    }

    async initPlayer(item) {
        let source = await this.getPlayerUrl(item.isLivePlaying ? 'live' : item.id)
        let setup = await this.createKeyOSSetup(item, source);
        try {
            keyosPlayer.init('m3_player', setup)
            this.player = keyosPlayer.getPlayer('m3_player')
        } catch (error) {
            error['content_id'] = item.id;
            this.showError(error)
            this.hideIntro();
            window.alert('Hiba történt a lejátszó előkészítése közben!')
        }
        this.initialized_player = true;
        this.keyOSListeners()
    }

    async createKeyOSSetup(item, source) {
        let sourceMpegDash = source.mpeg_dash;
        let sourceHls = source.hls;

        if (item && item.hasSubtitle && !item.isLivePlaying) {
            let subtitles = [{
                type: 'srt',
                path: '/subtitle/' + item.id + '.srt',
                language: 'hungarian',
                label: 'Magyar',
                default: false,
            }]

            sourceMpegDash['subtitles'] = subtitles;
            sourceHls['subtitles'] = subtitles;
        }

        let sources;
        if (!source.mpeg_dash.drm && source.hls.drm) {
            sources = [sourceHls, sourceMpegDash]
        } else {
            sources = [sourceMpegDash, sourceHls]
        }

        let setup = this.keyosSetupDefaults();
        if (item && item.id) {
            setup['image'] = '/images/m3/' + item.id + '.jpg';
        }
        setup['playlist'] = {
            'sources': sources
        };
        setup['drm'] = {
            'playready': {
                laUrl: `${source.proxy_url}?drm-type=playready&type=${source.type}`
            },
            'widevine': {
                laUrl: `${source.proxy_url}?drm-type=widevine&type=${source.type}`
            },
            'fairplay': {
                certificateUri: sourceHls.certificateUri,
                laUrl: `${source.proxy_url}?drm-type=fairplay&type=${source.type}`
            }
        }
        return setup;
    }

    keyosSetupDefaults() {
        return {
            'height': '100%',
            'autoplay': false,
            'extModules': `/public/build/modules/${KEYOSVERSION}/`,
            'mute': false,
        }
    }


    showError(error) {
        $('#m3_player').addClass('error')
        $('#m3_player').html(`
            <div class="error-box">
                Hiba történt!<br>
                Amennyiben a lejátszás többszöri próbálkozás után is sikertelen, kérjük, próbálkozzon böngészője frissítésével vagy másik böngészővel!<br>
                Ha a probléma ezt követően sem oldódik meg, hibajelzést küldhet a lentebb található "Hibajelentés" gombbal.<br>
                ${error.code ? `<br>Hibakód: ${error.code}` : ''}
            </div>
        `);
    }

    keyOSListeners(item) {
        this.player.on('play', () => {
            this.hideIntro();
        })
        this.player.on('error', (error) => {
            error['content_id'] = item.id;
            this.showError(error);
            this.hideIntro();
        });
    }

    async manageView(item) {
        $('.active-title').text(item['title']);
        if (item.subtitle) {
            $('.active-subtitle').text(item['subtitle']);
            $('.active-subtitle').removeClass('hidden');
        } else {
            $('.active-subtitle').text('');
            $('.active-subtitle').addClass('hidden');
        }
        $('.active-production').text(item['company'] || '');
        $('.active-description').text(item['short_description']);

        let infoLine = [];
        if (item.quality && item.quality !== 'SD') infoLine.push(`<span class="show-icon resolution-icon ${item.quality}">${item.quality}</span>`);
        if (item.pg && !isNaN(item.pg)) infoLine.push(`
            <div class="active-pg">
                <span class="pg pg-${item.pg}">
                    ${item.pg}
                </span>
            </div>
        `);
        if (item.year) infoLine.push(item.year);
        if (item.genre.length) infoLine.push(item.genre.join(', '));
        if (infoLine.length) $('.active-info-line').html(infoLine.join(' | '));
        if (item.company) $('.active-company').html(item.company)
        $('.active-info').html(item['info'].join('<br>'));
        $('.active-full-description').html(item['description']);
        $('.active-extended-info').html(item['extended_info'].join('<br>'));
        $('.active-contributors').html((item['contributors'] || []).join('<br>'));
        this.info_scrollbar.update();

        let bg = 'url(' + '/images/m3/' + item.id + ')';
        $('.hero-bg').attr('data-id', item.id);
        $('.hero-bg').addClass('switch');
        setTimeout(() => {
            $('.hero-bg').css('background-image', bg);
        }, 200)
        setTimeout(() => {
            $('.hero-bg').removeClass('switch');
        }, 400)

        if (item.isLivePlaying || (item.ratio === '16:9' && item.playable)) {
            $('.hero-player').addClass('wide');
            $('.hero-player-container').addClass('wide');
        } else {
            $('.hero-player').removeClass('wide');
            $('.hero-player-container').removeClass('wide');
        }

        if (item.isLivePlaying) {
            $("#toggle_embed").removeClass('hidden');
            $('.live-info').removeClass('toggled');
            $('.live-info').addClass('hidden');
            $('.active-help').addClass('hidden');
            $(".status-line").removeClass('hidden');
            $(".live-time").removeClass('hidden');
        } else {
            $("#toggle_embed").addClass('hidden');
            $('.live-info').addClass('toggled');
            $('.live-info').removeClass('hidden');
            $('.active-help').removeClass('hidden');
            $(".status-line").addClass('hidden');
            $(".live-time").addClass('hidden');
        }
    }

    initIntro(item) {
        $('.hero-intro-player video').off();
        let video = '';
        if (item.isLivePlaying) {
            video = 'M3_logo_no_audio_360p.mp4';
        } else {
            video = this.config.pg[item.pg] ? this.config.pg[item.pg] : this.config.pg['No PG'];
        }
        $('.hero-intro-player').html(`
            <div class="play"><i class="fas fa-play"></i></div>
            <video width="400" ${AUTOSTART || !item.isLivePlaying ? 'autoplay' : ''} ${item.isLivePlaying ? 'loop' : ''}>
                <source src="${'/public/video/' + video}" type="video/mp4">
            </video>
        `);
        $('.hero-intro-player video').on('play', () => {
            $('.hero-intro-player .play').addClass('invisible');
            if (item.isLivePlaying) {
                this.hideIntro();
                this.player.play();
            }
        });
        $('.hero-intro-player video').get(0).volume = this.volume;
        $('.hero-intro-player').show()
        setTimeout(() => {
            $('.hero-intro-player video').on('ended', () => {
                this.hideIntro();
                this.player.play()
            });
            $('.hero-intro-player video, .hero-intro-player .play').click(() => {
                $('.hero-intro-player video').get(0).play();
            })
        }, 100)
    }

    hideIntro() {
        $('.hero-intro-player').html(``)
        $('.hero-intro-player').hide()
        if (this.initialized_player) {
            this.player.volume(this.volume)
            this.player.play()
        }
    }

    toggleEmbed() {
        $('.hero-player-modal').toggleClass('toggled');
    }

    setupListeners() {
        this.info_scrollbar = new PerfectScrollbar('.active-extended-info-modal-inner');

        $('#toggle_embed').click(() => {
            this.toggleEmbed()
        })

        $('.hero-player-modal-close').click(() => {
            this.toggleEmbed()
        })

        $('.hero-player-modal-inner').click(() => {
            $('.hero-player-modal-inner textarea').focus();
            $('.hero-player-modal-inner textarea').select();
        })

        $('#switch-to-uhd').click(async (e) => {
            let id = $(e.target).closest('[data-id]').attr('data-id');
            if (id) {
                let item = await this.getItem(id)
                item['isLivePlaying'] = false;
                await this.setupPlayer(item);
            }
            alert('hello');
        })

        $(window).on('popstate', () => {
            let url = window.location.pathname;
            let id = url.substring(url.lastIndexOf('/') + 1) !== 'm3' ? url.substring(url.lastIndexOf('/') + 1) : id;
            this.start(id)
        })
    }

    async getItem(id) {
        return JSON.parse(
            await $.ajax({
                url: `/api/m3/v3/item?id=${id}`,
                method: 'GET',
            }))
    }

    async getLive() {
        let result = JSON.parse(await $.ajax({
            method: 'GET',
            url: '/api/m3/v3/live',
        }));
        return result.live
    }

    async getPlayerUrl(target, uhd = false) {
        let mh = '';
        if (!uhd) {
            mh = 1080;
        }

        return JSON.parse(
            await $.ajax({
                url: '/api/m3/v3/stream',
                data: {
                    target: target,
                    platform: 'web',
                    type: 'm3',
                    mh: mh
                },
                method: 'GET'
            })
        )
    }
}

export default Player