import Component from '../Component';

class ReactionsView extends Component {
  constructor(model, container) {
    super();
    this._model = model;
    this.state = {
      id: '1',
      likes: '3',
      dislikes: '4',
      user: 'like' // like/dislike/null
    };

    this.container = container;
    this.allowed = $(this.container).is('.not-allowed') ? false : true;

    $(this.container).on('click', e => {
      this.handleClick(e.target);
    })
  }

  update(id = this.state.id) {
    $(this.container).addClass('faded');
    this.emit('getReactions', id);
  }

  handleClick(target) {
    if (!this.allowed) {
      $('.reaction-alert p').html('Bejelentkezés szükséges!')
      return;
    }
    let reaction = null;
    if ($(target).is('.reaction')) {
      reaction = $(target).attr('data-reaction');
    } else if ($(target).parent().is('.reaction')) {
      reaction = $(target).parent().attr('data-reaction');
    } else {
      return;
    }
    if (reaction === this.state.user) {
      reaction = null;
    }
    let input = {
      reaction, 
      id: this.state.id
    }
    $('.reaction-alert').remove();
    $(this.container).addClass('faded');
    this.emit('reaction', input);
  }
  
  render(prevState) {
    let content = `
    <span class="reaction like ${this.state.user === 'like' ? 'active' : ''}" data-reaction="like">
      <i class="fas fa-thumbs-up"></i> <span class="reaction-count">${this.state.likes}</span>
    </span>
    <span class="reaction dislike ${this.state.user === 'dislike' ? 'active' : ''}" data-reaction="dislike">
      <i class="fas fa-thumbs-down"></i> <span class="reaction-count">${this.state.dislikes}</span>
    </span>
    <div class="reaction-alert"><br><p class="small"></p></div>
    `
    $(this.container).html(content);
    $(this.container).removeClass('faded');
  }
}

export default ReactionsView;