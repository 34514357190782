import {dailyProgramRowSlickSetup} from "./dailyProgramConfig";
import 'slick-carousel'

require('slick-carousel/slick/slick.css')


class DailyProgram {
    constructor(player) {
        this._player = player;

        this.dailyProgram = null;

        this.getDailyProgram();

        this.setupListeners();
    }

    renderDailyProgram() {
        $('#seriesCollapse').collapse('hide');

        $('#daily_program_container').addClass('hidden');

        let dailyProgramRows = {
            '#daily_series': [],
            '#daily_movies': [],
            '#daily_uhd': [],
            '#daily_highlighted': [],
            '#daily_catchup': [],
        };

        Object.entries(this.dailyProgram).forEach(([programId, program]) => {
            let item = this.renderItem(program);

            if (program.quality === 'UHD') dailyProgramRows['#daily_uhd'].push(item);
            if (!program.isHighlighted_b && program.isSeries) dailyProgramRows['#daily_series'].push(item);
            if (!program.isHighlighted_b && !program.isSeries) dailyProgramRows['#daily_movies'].push(item);

            if (!program.isSeries) {
                if (program.isHighlighted_b) dailyProgramRows['#daily_highlighted'].push(item);
                if (program.isBroadcastCatchup_b) dailyProgramRows['#daily_catchup'].push(item);
            } else {
                program.availableEpisodes.forEach(episode => {
                    let item = this.renderItem(episode);

                    if (episode.isHighlighted_b) dailyProgramRows['#daily_highlighted'].push(item);
                    if (episode.isBroadcastCatchup_b) dailyProgramRows['#daily_catchup'].push(item);
                })
            }
        });

        Object.entries(dailyProgramRows).forEach(([row, html_content]) => {
            if (html_content.length) {
                $(row).html(html_content.join(''));
            } else {
                $(row).closest('.dp-carousel-container').addClass('hidden');
            }
            if ($(row).hasClass('slick-initialized')) {
                $(row).slick('destroy');
            }
            $(row).slick(dailyProgramRowSlickSetup);
        });

        $('#daily_uhd').children('.dp-item').addClass('uhd-item');

        $('#daily_program_container').removeClass('hidden');
    }

    renderItem(item) {
        return `
        <div class="dp-item" 
            ${item.isDPSeries ? `
            data-series="${encodeURIComponent(item.seriesId)}"
            ` : `
            data-id="${item.id}"
            `}
            >
            <div class="dp-item-inner ${item.isDPSeries ? `dp-series-item` : ''}">
                <div class="show-bg" style="background-image: url(${'/images/m3/' + item.id})"></div>
                <div class="show-gradient"></div>
                <div class="show-play">
                    <i class="${item.isDPSeries ? `fas fa-history` : `fas fa-play`}"></i>
                </div>
                ${!isNaN(item.pg) ? `
                <div class="show-pg">
                    <span class="pg pg-${item.pg}">${item.pg}</span>
                </div>
                ` : ''}
                <div class="show-icons">
                    ${item.quality && item.quality !== 'SD' ? `<span class="show-icon resolution-icon ${item.quality}">${item.quality}</span>` : ''}
                    ${item.hasSubtitle ? `<span class="show-icon">CC</span>` : ''}
                </div>
                <div class="show-title">
                    <h5>${item['title']}</h5>
                    ${item['subtitle'] ? `<h6 class="subtitle">${item.subtitle}</h6>` : ''}
                </div>
            </div>
        </div>
        `;
    }

    filterDailyProgram(dailyProgram) {
        let data = {};
        dailyProgram.forEach(program => {
            if (program.isSeries) {
                if (!data[program.seriesId]) {
                    data[program.seriesId] = {
                        'seriesId': program.seriesId,
                        'isSeries': true,
                        'isDPSeries': false,                                    // series with more than 1 episodes
                        'title': program.title,
                        'subtitle': program.subtitle,

                        'id': program.id,                                       // id of the earliest available episode
                        'hasSubtitle': program.hasSubtitle,
                        'pg': program.pg,
                        'availableEpisodes': [],

                        'quality': 'SD',                                        // highest quality of episodes
                        'isHighlighted_b': program.isHighlighted_b,             // at least 1 'isHighlighted_b' episode
                    }
                }
                data[program.seriesId]['availableEpisodes'].push(program);

                data[program.seriesId]['availableEpisodes'].sort((a, b) => {
                    if (parseInt(a.episode) < parseInt(b.episode)) return -1;
                    if (parseInt(a.episode) > parseInt(b.episode)) return 1;
                    return 0;
                });

                if (data[program.seriesId]['availableEpisodes'].length > 1) {
                    data[program.seriesId]['subtitle'] = data[program.seriesId]['availableEpisodes'].length + ' visszajátszható epizód';
                    data[program.seriesId]['isDPSeries'] = true;
                }

                data[program.seriesId]['id'] = data[program.seriesId]['availableEpisodes'][0].id;
                if (program.hasSubtitle) data[program.seriesId]['hasSubtitle'] = true;
                let programPgNum = isNaN(Number(program.pg)) ? 0 : Number(program.pg);
                let seriesPgNum = isNaN(Number(data[program.seriesId]['pg'])) ? 0 : Number(data[program.seriesId]['pg']);
                if (programPgNum > seriesPgNum) data[program.seriesId]['pg'] = program.pg;

                if (program.quality === 'UHD') data[program.seriesId]['quality'] = 'UHD';
                if (program.quality === 'HD' && data[program.seriesId]['quality'] !== 'UHD') data[program.seriesId]['quality'] = 'HD';
                if (program.isHighlighted_b) data[program.seriesId]['isHighlighted_b'] = true;

            } else {
                data[program.id] = program;
            }
        });
        return data;
    }

    toggleEpisodeList(event, seriesId) {
        let container = $(event.target).closest('.dp-carousel-container');
        let collapse = $(container).find('.collapse');

        Array.from($(container).find('[data-series]')).forEach(el => $(el).removeClass('toggled'));
        $(container).find(`[data-series="${seriesId}"]`).addClass('toggled');

        seriesId = decodeURIComponent(seriesId);
        let episodes = this.dailyProgram[seriesId].availableEpisodes;

        let list = episodes.map(ep => {
            return `
            <div class="episode-list-item" data-id=${ep.id}>
                <i class="fas fa-play-circle"></i>
                ${ep.subtitle}
                ${ep.quality && ep.quality !== 'SD' ?
                `<span class="show-icon resolution-icon ${ep.quality}">${ep.quality}</span>` : ''}
            </div>
            `;
        }).join('');
        let title = `<h3>${episodes[0].title}</h3>`;
        $(collapse).find('.series-title').html(title);
        $(collapse).find('.episode-list').html(list);
        $(collapse).attr('data-series', encodeURIComponent(seriesId));
        $(collapse).collapse('show');
        if (!$(container).find('.episode-list').isInViewport()) {
            let top = $(container).offset().top;
            $("html, body").animate({scrollTop: top - 50}, 600);
        }
    }

    setupListeners() {
        $('#daily_program_container').click((e) => {
            e.stopPropagation();
            let id = $(e.target).closest('.dp-item').attr("data-id") || $(e.target).closest('.episode-list-item').attr("data-id");
            if (id) {
                this._player.updateUrl(id);
                this._player.start(id);
            }
        });

        $('#daily_program_container').click((e) => {
            let id = $(e.target).closest('.dp-item').attr("data-series");
            if (id) {
                this.toggleEpisodeList(e, id);
            }
        });

        $('.series-details-close').click((e) => {
            $(e.target).closest('.dp-carousel-container').find('.collapse').collapse('hide');
        });
    }

    getDailyProgram() {
        $.ajax({
            method: 'GET',
            url: '/api/m3/v3/daily_program',
            success: response => {
                response = JSON.parse(response);
                this.dailyProgram = this.filterDailyProgram(response.program)
                this.renderDailyProgram();
            }
        });
    }
}

export default DailyProgram