import Component from '../Component';

class ReactionsModel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getReactions(id) {
    console.log('getting reactions for ' + id );
    
    $.ajax({
      url: SITE_URL + 'reactions/' + id,
      method: 'GET',
      success: response => {
        response = JSON.parse(response);
        this.emit('gotReactions', response);
      }
    })
  }

  saveReaction(reaction) {
    $.ajax({
      method: 'POST',
      url: SITE_URL + 'reactions/save',
      data: {
        reaction: reaction['reaction'] ? reaction['reaction'] : 'delete',
        id: reaction['id']
      },
      success: response => {
        response = JSON.parse(response);
        console.log(response);
        this.emit('reacted', response)
      }
    })
  }
}

export default ReactionsModel;